// @ts-nocheck
require('es6-promise').polyfill();
require("@babel/polyfill");
import Vue from 'vue'
import Vuex from 'vuex'
import {
	getField,
	updateField
} from 'vuex-map-fields';
import base from '../base';
import {addWord_types,editWord_types} from './types';
Vue.use(Vuex)
var store = new Vuex.Store({
	state: {
		acl_current: [],
		dictionaryWord: [],
		role_types: [],
		types:[],
		heading:'',
		isloading:true,
		counter_sms:1,
      	counter_idi:1,
      	counter_eks:1,
		addWord: addWord_types(),
		editDictWord:editWord_types(),
		create_permission:[],
		checked_sub_ids:[],
		changed_rows:[],
		edit_counter_sms:1,
		edit_counter_eks:1,
		edit_counter_idi:1,
		edituserDetail:'',
		editpermission:'',
		isEditWordFetched:false,
		sub_id_sequence:1,
		add_new_sub_id:0,
		new_index_type:[],
		s_n_request_new_word_data:[],
		fromAddWord:false,
		isPendingChangeRequest:false,
		editedHistory:[],
        ordbank: [],
		showAlert:true,
		canEditChangeRequests:false,
	},
	getters: {
		getField,
		aclLoaded(state) {
			return () => {
			  return state.acl_current.length !== 0
			}
		  }
	},
	mutations: {
		updateField,
		resetState(state){
				state.acl_current= [];
				state.dictionaryWord= [];
				state.role_types= [];
				state.types=[];
				state.heading='';
				state.isloading=true;
		},
		addictionaryWordRow(state) {
			state.addresses.push({
				id: '',
				sub_id: '',
				type: '',
				text: '',
				isEditable: 1
			});
		},
		userPermission(state, userPermission) {
			state.role_types.push(userPermission);
		},
		userRole(state, role) {
			state.acl_current.splice(0, 1);
			state.acl_current.push(role);
		},
		change_addword(state,val){
			state.addWord=val;
		},
		change_editDictword(state,val){
			state.editDictWord=val;
		},
		update_counter(state,val){
			state.counter_eks=val.counter_eks;
			state.counter_sms=val.counter_sms;
			state.counter_idi=val.counter_idi;
		},
		change_counter(state,val){
			if(val=="sms"){
				++state.counter_sms;
			}
			if(val=="idi"){
				++state.counter_idi;
			}
			if(val=="eks"){
				++state.counter_eks;
			}
		},
		remove_counter(state,val){
			if(val=="sms"){
				--state.counter_sms;
			}
			if(val=="idi"){
				--state.counter_idi;
			}
			if(val=="eks"){
				--state.counter_eks;
			}
		},
		restore_state(state,val){
			state.addWord=val;
			state.counter_eks=1;
			state.counter_idi=1;
			state.counter_sms=1;
		},
		getCreatePermission(state,val){
			state.create_permission=val;
		},
		update_checked_sub_ids(state,val){
			state.checked_sub_ids=val;
		},
		restore_checked_sub_ids(state){
			state.checked_sub_ids=[];
		},
		update_changed_rows(state,val){
			state.changed_rows=val;
		},
		restore_changed_rows(state){
			state.changed_rows=[];
		},
		add_types(state,val){
			state.types.push(val);
		},
		editChangeRequests(state,val){
			state.canEditChangeRequests=val;
		},
		update_dictword(state,val){
			if(!val){
				state.dictionaryWord=[];
				state.editDictWord=editWord_types();
			}
			else{

				state.dictionaryWord.push(val);
			}
		},
		update_isloading(state,val){
			state.isloading=val;
		},
		checkid(state){
			if(state.dictionaryWord[0].id==""){
				state.dictionaryWord.splice(0, 1);
			}
		},
		types(state){
			state.types=[];
		},
		update_edit_counter(state,val){
			state.edit_counter_sms=val.sms;
			state.edit_counter_eks=val.eks;
			state.edit_counter_idi=val.idi;
		},
		increment_edit_counter(state,val){
			if(val=="sms"){
				++state.edit_counter_sms;
			}
			if(val=="idi"){
				++state.edit_counter_idi;
			}
			if(val=="eks"){
				++state.edit_counter_eks;
			}
		},
		decrement_edit_counter(state,val){
			if(val=="sms"){
				--state.edit_counter_sms;
			}
			if(val=="idi"){
				--state.edit_counter_idi;
			}
			if(val=="eks"){
				--state.edit_counter_eks;
			}
		},
		updateUser(state,val){
			state.edituserDetail=val.userDetail,
			state.editpermission=val.permission
		},
		update_single_dictword(state,payload){
			const {type ,dictionary_word} = payload;
			var elm='';
			var defaultPermission=["Nny","B","N","E"];
			elm = state.editDictWord.find(p => p.type == type);
			if(elm!==undefined && dictionary_word!==undefined){
				elm.id= dictionary_word.id,
				elm.sub_id= dictionary_word.sub_id,
				elm.index_sub_id= dictionary_word.index_sub_id,
				elm.type= dictionary_word.type,
				elm.text= dictionary_word.text,
				elm.isEditable= dictionary_word.isEditable,
				elm.isNew=false,
				elm.isPermitted=true,
				elm.updated_at= dictionary_word.updated_at
			}
			else if(elm!==undefined && dictionary_word==undefined){
				elm.id= state.dictionaryWord[0].id,
				elm.sub_id= "",
				elm.index_sub_id=elm.index_sub_id
				elm.type= elm.type,
				elm.text= elm.text,
				elm.isEditable= (state.edituserDetail.is_admin||state.edituserDetail.is_editor) ? true : state.types[0].includes(elm.type),
				elm.isNew=true,
				elm.isPermitted=!state.edituserDetail.is_admin && !state.edituserDetail.is_editor && !state.types[0].includes(elm.type) && !defaultPermission.includes(elm.type.split('-')[0])  ? false :true,
				elm.updated_at=''
			}
		},
		update_multiple_dictword(state,payload){
			const {dictionary_word,elm} = payload;
			var defaultPermission=["Nny","B","N","E"];
			if(elm!==undefined && dictionary_word!==undefined){
				elm.id= dictionary_word.id,
				elm.sub_id= dictionary_word.sub_id,
				elm.status_id= dictionary_word.status_id,
				elm.type= dictionary_word.type,
				elm.text= (dictionary_word.status_id == 3) ? '' :dictionary_word.text,
				elm.index_sub_id = dictionary_word.index_sub_id,
				elm.isEditable= dictionary_word.isEditable,
				elm.isNew=false,
				elm.isPermitted=true,
				elm.updated_at= dictionary_word.updated_at
			}
			else if(elm!==undefined && dictionary_word==undefined){
				elm.id= state.dictionaryWord[0].id,
				elm.sub_id= '',
				elm.type= elm.type,
				elm.text= (elm.status_id ==3)? '': elm.text,
				elm.index_sub_id = elm.index_sub_id,
				elm.status_id= elm.status_id,
				elm.isEditable= (state.edituserDetail.is_admin||state.edituserDetail.is_editor) ? true : state.types[0].includes(elm.type),
				elm.isNew=true,
				elm.isPermitted=!state.edituserDetail.is_admin && !state.edituserDetail.is_editor && !state.types[0].includes(elm.type) && !defaultPermission.includes(elm.type.split('-')[0])? false :true,
				elm.updated_at=''
			}
		},
		update_sub_id_seq(state,val){
			state.sub_id_sequence=val;
		},
		update_editDictWord(state,val){
			state.editDictWord=val;
		},
		restore_edit_counter(state){
			state.edit_counter_sms=1;
			state.edit_counter_eks=1;
			state.edit_counter_idi=1;
			state.sub_id_sequence=1;
		},
		change_new_sub_id(state,val){
			state.add_new_sub_id=val;
		},
		update_new_index_type(state,val){
			state.new_index_type=val;
		},
		update_s_n_request_new_word_data(state,val){
			state.s_n_request_new_word_data=val;
		},
		update_fromaddword(state,val){
			state.fromAddWord=val;
		},
		updateIsPendingChangeRequest(state,val){
			state.isPendingChangeRequest=val;
		},
        updateEditedHistory(state,val){
            state.editedHistory=val;
        },
        updateOrdbank(state,val){
            state.ordbank=val;
        },
		updateShowAlert(state,val){
			state.showAlert=val;
		}
	},
	actions:{
		getCreatePermission(store,val){
			base.defaults.headers.common['Content-Type'] = 'application/json'
			base.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('editorStore.jwt')
			return base.get('getCreatePermissions')
				.then(response => {
					let contr_error = response.data.error_exist;
					if(contr_error){
						Vue.toasted.error(response.data.err);
						//this.isloading=false;
					}
					else{
						store.commit('getCreatePermission',response.data);
					}
				}).catch(error => {
					Vue.toasted.error(response.data.err);
				});
		},
		change_addword(context,addWord){
			context.commit('change_addword',addWord);
		},
		change_editDictword(context,editDictWord){
			context.commit('change_editDictword',editDictWord);
		},
		/**
		 *  We'll use this mutation to dynamically add new rows.
		 */
		searchDictionaryWord(store, val) {
			var permission = [];
			var userDetail=[];
			base.defaults.headers.common['Content-Type'] = 'application/json'
			base.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('editorStore.jwt')
			return base.post('userpermission')
				.then(response => {
					let contr_error = response.data.error_exist;
					if(contr_error){
						Vue.toasted.error(response.data.err);
						store.commit('update_isloading',false);
					}
					else{
					permission = response.data.roles_permission.role_types;
					userDetail= response.data.user;
					store.commit('updateUser',{permission:permission,userDetail:userDetail});
					store.commit('types');
					if (permission) {
						permission = response.data.roles_permission.role_types.filter(
							(x) => {
								return x.permission['name'] == 'writable';
							}).filter(e => e.types)
							.map(e => e.types.map(e => e.name))
							.reduce((a, b) => {
								return a.concat(b);
							}, []);
					}
					if(userDetail.is_editor){
						response.data.editor_permission.forEach(element => {
							store.commit('add_types',element.types);
						});
						store.commit('editChangeRequests',userDetail.editor.can_edit_change_request);
					}else if(!userDetail.is_admin){
						store.commit('add_types',permission);
					}
					let textId = val.id;
					store.commit('update_dictword');
					store.commit('restore_edit_counter');
					if(textId==''||textId==null){
						//store.commit('update_isloading',false);
					}else{
						return base.get('searchword', {
							params: {
								textId: textId
							}
						})
						.then(
							response => {
								store.commit('update_s_n_request_new_word_data',response.data.s_n_request_new_word_data);
								store.commit('update_fromaddword',response.data.fromAddWord);
								store.commit('updateIsPendingChangeRequest',response.data.is_pending_change_request);
                                store.commit('updateEditedHistory',response.data.edited_history);
                                store.commit('updateOrdbank',response.data.ordbank);
								store.commit('updateShowAlert',true);
								let contr_error = response.data.error_exist;
								if(contr_error){
									Vue.toasted.error(response.data.err);
									store.commit('update_isloading',false);
								}
								else{
									let cou_eks=-1;
									let cou_sms=-1;
									let cou_idi=-1;
									let count=0;
									let ukr_eks_count = -1;
									let ukr_sms_count = -1;
									let ukr_idi_count = -1;
									let isSmsStarted=false;
									response.data.searchResult.forEach((element,key) => {
										let defaultPermission = ["Nny","B","N","E"];
										if ((userDetail.is_admin||userDetail.is_editor) ? true : permission.includes(element.type)||defaultPermission.includes(element.type.split('-')[0])) {
											var isOther=false;
											var type=element.type.split('-')[1]
											if(type!=='sms' && type!=='eks' && type!=='idi' && type!='div'){
												isOther=true;
											}
											else if(type!=='div'){
												isOther=false;
											}
											if(type=='div' && !isSmsStarted){
												isOther=true;
											}
											if(type=='div' && isSmsStarted){
												isOther=false;
											}
											if(element.type=='E-sms'){
												cou_sms=cou_sms+1;
												isSmsStarted=true;
											}
											else if(element.type=='N-sms' && response.data.searchResult[key-1].type!=='E-sms'){
												cou_sms=cou_sms+1;
											}
											if(element.type=='E-eks'){
												cou_eks=cou_eks+1;
											}
											else if(element.type=='N-eks' && response.data.searchResult[key-1].type!=='E-eks'){
												cou_eks=cou_eks+1;
											}
											if(element.type=='E-idi'){
												cou_idi=cou_idi+1;
											}
											else if(element.type=='N-idi' && response.data.searchResult[key-1].type!=='E-idi'){
												cou_idi=cou_idi+1;
											}
											if(type=='sms'){
												count=cou_sms;
											}
											if(type=='eks'){
												count=cou_eks;

											}
											if(type=='idi'){
												count=cou_idi;
											}
											if (element.type == 'Ukr-eks' && response.data.searchResult[key-1].type!=='Tur-eks') {
													ukr_eks_count = ukr_eks_count + 1
													count =ukr_eks_count
											}
											if (element.type == 'Ukr-sms' && response.data.searchResult[key-1].type!=='Tur-sms') {
												ukr_sms_count = ukr_sms_count + 1
												count =ukr_sms_count
										    }
										   if (element.type == 'Ukr-idi' && response.data.searchResult[key-1].type!=='Tur-idi') {
													ukr_idi_count = ukr_idi_count + 1
													count =ukr_idi_count
											}

											var obj={
												id: element.id,
												sub_id: element.sub_id,
												index_sub_id: element.sub_id,
												status_id: element.status_id ? element.status_id :'',
												type: element.type,
												text: element.text,
												isEditable: (userDetail.is_admin||userDetail.is_editor) ? true : permission.includes(element.type),
												index:isOther?null:(element.index != null)?element.index:count,
												updated_at:element.updated_at
											}


											store.commit('update_dictword',obj);
										}
									});
									store.commit('checkid');
									store.commit('update_isloading',false);
								}
							});
						}
					}
				});
		},
	}
})
export default store;
